$(function(){
	toastr.options.closeMethod = 'fadeOut';
	toastr.options.closeDuration = 300;
	toastr.options.closeEasing = 'swing';
	toastr.options.progressBar = true;
	toastr.options.positionClass = 'toast-top-right';
});

$(function(){
	/**
	 * Bind logout click
	 */
	$("#logout").bind("click", function(){
		Cookies.set("token", null);
		window.location = "/";
	});

	$('[data-toggle="tooltip"]').tooltip();


});

numeral.register('locale', 'ru', {
	delimiters: {
		thousands: ' ',
		decimal: ','
	},
	abbreviations: {
		thousand: 'тыс',
		million: 'млн',
		billion: 'блн',
		trillion: 'трлн'
	},
	ordinal : function (number) {
		return number === 1 ? 'р.' : 'руб.';
	},
	currency: {
		symbol: '₽'
	}
});
numeral.locale('ru');





